<template>
  <v-dialog v-model="openDialog" max-width="370px" persistent>
    <v-card class="dialog-modal">
      <v-form v-model="valid" @submit.prevent="updateEmail"
      v-if="encryptEmail === 'true'">
        <v-card-title v-html="message" />
        <v-flex class="pa-6">
            <TextField
                @emitTextValue="getEmailAddress"
                label="Email address"
                :rules="emailRules"
                placeholder="hello@timeflyz.com"
                :value="email"
            />
          <v-row align="center" justify="end">
            <Button
              class="cancel-button"
              buttonClass="error-link"
              @buttonClicked="cancleAlert"
              type="link"
              value="Cancel"
            />
            <Button
                type="submit"
                value="Update"
                color="primary"
                height="40px"
                width="100%"
                :disabled="!valid"
            />
          </v-row>
        </v-flex>
      </v-form>
      <v-form v-model="valid" @submit.prevent="goToHomepage"
      v-if="encryptEmail !== 'true'">
      <v-flex class="pa-6">
        <v-card-title v-html="message" />
        <Button
            type="submit"
            value="Go To Homepage"
            color="primary"
            height="40px"
            width="100%"
        />
      </v-flex>
      </v-form>
        <div v-if="showDialog">
          <v-dialog v-model="showDialog" max-width="370px">
            <Dialog
              :dialogData="toasterMessage"
              :onCloseDialog="closeDialog"
              mode="snackbar-error"
            />
          </v-dialog>
        </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  SNACKBAR_TIMEOUT,
  HTTP_STATUS_CODE,
  TOASTER_MESSAGE,
} from '@/constants';
import validation from '@/validations';
import Dialog from '@/common/ErrorDialog.vue';
import TextField from './TextField.vue';
import Button from './Button.vue';

const {
  updateAppleEmail,
} = require('@/services/user/user.service');

export default {
  name: 'AppleAuthorize',
  props: ['details'],
  data() {
    return {
      emailRules: [validation.FORM_VALIDATION.required, validation.FORM_VALIDATION.email],
      email: '',
      valid: true,
      timeout: SNACKBAR_TIMEOUT,
      openDialog: false,
      showDialog: false,
      encryptEmail: '',
      message: '',
      accessToken: '',
      loadingInProgress: false,
      toasterMessage: '',
      popupMessageType: '',
    };
  },
  components: {
    TextField,
    Button,
    Dialog,
  },
  created() {
    this.receiveDetails();
  },
  methods: {
    async receiveDetails() {
      const deriveData = await localStorage.getItem('appleLogin');
      const parseJSON = JSON.parse(deriveData);
      const state = parseJSON.state !== null ? parseJSON.state : null;
      const code = parseJSON.code !== null ? parseJSON.code : null;
      const email = parseJSON.email !== null ? parseJSON.email : null;
      this.accessToken = parseJSON.token !== null ? parseJSON.token : null;
      if (state === 'true' && code === 'false') {
        this.$cookies.set('isLoggedIn', true);
        this.$router.push('/hotels');
      } else {
        this.encryptEmail = code;
        this.message = code === 'true' ? 'Thanks for registering with TimeFlyz. <br /> Unfortunately we cannot create your account without a valid email address, please update below.' : code;
        this.email = email;
        this.openDialog = true;
      }
    },
    getEmailAddress(email) {
      this.email = email;
    },
    cancleAlert() {
      this.snackbar = true;
      this.showDialog = true;
      this.popupMessageType = 'redirectHome';
      this.toasterMessage = "Unfortunately we haven't been able to create your account, but you can continue to search hotels on our site.";
    },
    goToHomepage() {
      this.$router.push({ path: '/' });
    },
    async updateEmail() {
      const updateData = {
        email: this.email,
      };
      try {
        const updatedUserResponse = await updateAppleEmail(updateData, this.accessToken);
        if (updatedUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.snackbar = true;
          this.showDialog = true;
          this.popupMessageType = 'login';
          this.toasterMessage = 'Thank you for updating your email address. Please check your inbox and verify your account.';
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.showDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    closeDialog() {
      this.snackbar = false;
      this.showDialog = false;
      if (this.popupMessageType === 'login') {
        localStorage.removeItem('appleLogin');
        this.$cookies.set('token', this.accessToken);
        this.$cookies.set('isLoggedIn', true);
        this.$router.push('/hotels');
      }
      if (this.popupMessageType === 'redirectHome') {
        localStorage.removeItem('appleLogin');
        this.$router.push({ path: '/' });
      }
    },
  },
};
</script>


<style lang="scss">
.tf-app{
  width: 30%;
  display: flex;
  align-items: center;
}
.cancel-button {
  margin-right: 1rem;
}
.dialog-modal {
  .v-card__title {
    padding: 17px 24px !important;
    border-bottom: 1px solid #c4c5cc !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .dialog-container {
    padding: 24px;
  }
  .text-field-container {
    margin-top: 15px;
  }
  .alert {
    ::v-deep .theme--light.v-btn.v-btn--icon:hover,
    ::v-deep .theme--light.v-btn.v-btn--icon:focus {
      color: $error-color !important;
    }
  }
}
</style>
